import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { HContainer, Layout, Seo, TextGroup } from "components/common"
import Footer from "components/navigation/Footer"

import { Posts } from "src/components/post"

import Nav from "components/navigation/Nav"
import MobileNav from "src/components/navigation/mobile/MobileNav"

import BottomNewsletterCta from "src/components/common/shared/BottomNewsletterCta"

// import { NewsPosts } from "src/components/news"

// import NewsLetterForm from "components/forms/NewsLetterForm"

import { device, useContentfulSection, useMeta } from "utils"

const ListingsPage = ({ data: { listing, listingPosts, bottom_cta } }) => {
  const { listings_title } = useContentfulSection(listing.sections);

  const posts = {
    edges: [...listingPosts.edges].sort(
      (a, b) => (new Date(a.node.date)).valueOf() - (new Date(b.node.date)).valueOf()
    )
  };

  const meta = useMeta(listing)

  return (
    <Layout>
      <Seo {...meta} />

      <Nav />
      <MobileNav />

      <Wrap>
        <HContainer className="listings-container">
          <TextGroup
            className="listings_title"
            title={listings_title.title}
            titleAs="h1"
            subtitle={listings_title.subtitle}
          />

          {/* posts */}
          <Posts posts={posts} type="listing" />

          <BottomNewsletterCta {...bottom_cta} />
        </HContainer>
      </Wrap>
      <Footer />
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 1;

  .listings_title {
    --tg-text-align: center;
  }

  .listings-container {
    /* --section-max-width: 1080px; */
    --section-max-width: 925px;
    padding-top: var(--sp-mobile-nav);
    padding-bottom: var(--sp-section-mobile);

    @media ${device.laptop} {
      padding-top: var(--sp-nav);
      padding-bottom: var(--sp-section);
    }

    .posts {
      margin-top: var(--sp-64);
    }
  }
`

export const query = graphql`
    {
        listing: contentfulPage(pageId: { eq: "listing" }) {
            ...Page
        }
        listingPosts: allContentfulListing(
            filter: { slug: { ne: "placeholder" } }
        ) {
            edges {
                node {
                    ...Listing
                }
            }
        }
        bottom_cta: contentfulSection(sectionId: { eq: "bottom_newsletter_cta" }) {
            ...Section
        }
    }
`

export default ListingsPage
